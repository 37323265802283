// Sample question-answer key-value pairs
const questionAnswers = {
    "What does 'EpiDetectAI' stand for?": "EpiDetect is a combination of the words 'epidemic' and 'detection', indicative of the application of cutting-edge Artifcial Intelligence (AI) to assist in epidemic detection.",
    "What are the key features of EpiDetectAI?":"The key features of EpiDetectAI include: state-of-the-art large language models (LLM) that extract useful insights from vast epidemiology data, platform for an interactive communication with this data, automated alerts and recommendations based off real-time data/future prediction, and statistical models to learn trends in disease populations and spread",
    "What is EpiDetectGPT?": "EpiDetectGPT, built upon the foundations of ChatGPT, will serve as the interactive interface for users of the EpiDetectAI system. Its primary function is to facilitate queries and provide insights into epidemiological data, enhancing the decision-making process in outbreak detection and response. This feature enables users to query the system about specific cases, lab results, contact tracing efforts, and sample management statuses. EpiDetectGPT’s customization extends the tool's utility to a broad range of users, from field epidemiologists to decision-makers, by providing tailored information on demand.",
    "Is EpiDetectAI freely available to anyone?":"The project is distributed under the Apache License 2.0 and thus is available to the public. However, it is important to note that there are expenses associated with deploying and using the system. These expenses may include continous integration/continous development (CI/CD), training, organisational and country-wide adaptation, infrastructural setup, assistance and maintenance, and data protection measures.",
    "Will EpiDetectAI integrate with SORMAS?": "Yes, EpiDetectAI will be integrated with SORMAS (S-Core 1.0) and even DHIS2 through secured on-premises deployment and API endpoints,",
    "What measures will be taken towards AI safety and privacy?": "EpiDetectGPT will be trained exclusively on pseudonymized data that is only accessible to the health ministry or department operating the EpiDetectAI system. This approach ensures that the AI understands the context and nuances of the epidemiological data it analyzes while upholding strict data privacy standards. In addition, the AI systems are developed to comply with all applicable data protection regulations and ethical guidelines for AI in healthcare.",
    "What are the nature of data and models to be used?": "For outbreak response, EpiDetectAI 1.0 leverages models that are capable of processing temporal and spatial data to identify patterns indicative of an emerging outbreak. These models include but are not limited to, time-series analysis, cluster detection algorithms, and neural networks trained on historical outbreak data. By analyzing variations in reported cases, lab results, and other relevant indicators, these models can forecast potential outbreaks with a significant degree of accuracy.",
    "Can EpiDetectAI be adapted to suit specific organisations and countries?":"Thanks to our inherently modular design, all our provisions (such as EpiDetectGPT and Predictive Modelling) are scalable to various organisational sizes. Furthermore, our AI models can be developed to suit the available infrastructures, balancing out efficiency and associated computational costs. Hence, EpiDetectAI is adaptable to the unique needs of every country and organisation. ",
    "Where will the data and models be stored and accessed?":"The compilation of data and the unique AI models trained to suit governmental and/or institutional needs will be stored in a centralised database with access via secure API endpoints. More on the specifics of database location and configuration will be agreed upon with the in-house technical teams within your organisations.",
    "What is the S-Core?":"The S-Core 1.0 refers to the 'Surveillance, Outbreak Response Management and Analysis System' or SORMAS system, which was used to provide the initial data needed for the training and validation of our models. However, the S-core 2.0 is unique to EpiDetectAI and provides the added functionality of the AI-powered predictive analytics as well as connection to our interactive platform.",
  };

  export default questionAnswers;