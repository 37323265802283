import React from 'react';
import "./index.css"

const Page = ({ author, title, image, content, keywords }) => {

  // Function to count the number of words in the text
  const countWords = (text) => {
    return text.trim().split(/\s+/).length;
  }

  // Function to render each list item
  const renderListItem = (item, index) => {
    const wordCount = countWords(item);
    if (wordCount <= 10) {
      return <h4 className='text-start text-capitalize ps-4 pt-2' key={index}>{item}</h4>;
    } else {
      return <div className='text-justify'><p className='px-5 ' key={index}>{item}</p></div>;
    }
  }

  return (
    <div className='mx-4'>
      <br/>
      <h3 className='pt-2'>
        <p className='text-capitalize'>{title}</p>
        <p className='ps-3 fs-6 fw-normal m-0 text-start fst-italic'><strong>Keywords:</strong> {keywords} </p>
        <p className='ps-3 fs-6 fw-normal text-start fst-italic'><strong>Author(s):</strong> {author} </p>
      </h3>
      {image === "" ? (
        <></>
      ) : (
        <div className='blog-image-container'> 
          <img className='blog-image px-3 pb-2' src={image} alt={`${title}-${image}`}/>
        </div>
      )}
      <br/>
      {content.map((item, index) => (
        renderListItem(item, index)
      ))}
      {/* <p className='p-5 text-justify'>{content}</p> */}
    </div>
  );
};

export default Page;
