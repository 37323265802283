import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'; // Arrow down icon
import questionAnswers from '../../blog-vid-lists/faq-and-answers-list';
// import './styles.css'; // Import your custom CSS file

const FAQ = ({bgColor="white", border="", }) => {

  // State to track which question's answer is currently expanded
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  // Function to toggle the answer's visibility
  const toggleAnswer = (question) => {
    if (expandedQuestion === question) {
      setExpandedQuestion(null); // Collapse if already expanded
    } else {
      setExpandedQuestion(question); // Expand if not expanded
    }
  };

  return (
    <div className="px-4 mt-4">
      <ul className="list-group">
        {Object.entries(questionAnswers).map(([question, answer], index) => (
          <li key={index} 
              className="list-group-item" 
              style={{backgroundColor:bgColor, border:border, cursor: "pointer"}}
              onClick={() => toggleAnswer(question)}>
            <div className="d-flex justify-content-between">
              <span className='fw-bold'>{question}</span>
              <button
                className="btn btn-link"
                onClick={() => toggleAnswer(question)}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </button>
            </div>
            {expandedQuestion === question && (
              <p className="mt-2">{answer}</p>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FAQ;
