const slides = [
    {
      imageSrc: '../../images/Website-Photos/7.png',
      description: 'In today’s massively interconnected world, EpidetectAI is the first intelligent epidemic solution for early detection and rapid outbreak response of epidemics'
    },
    {
      imageSrc: '../../images/Website-Photos/6.png',
      description: 'By leveraging several AI algorithms, EpidetectAI interfaces with existing surveillance systems to inform key decisions for governments and healthcare instituitions.'
    },
    {
      imageSrc: '../../images/Website-Photos/15.png',
      description: 'With detailed visualizations and AI-based inference mechanisms EpidetectAI assures an excellent and interactive experience for both subject experts and non-technical personnel alike.'
    }
  ];
  
  export default slides;