import React from "react";
import "./index.css"
import ContactUsForm from "../../components/contact-us-form";

function ContactUs(){

    const iconLinks = [{"iconName":"icons8-telephone-100.png", "link":"tel:+44000000"}, 
            {"iconName":"icons8-email-100.png", "link":"mailto:www.gmail.com"}, 
            {"iconName":"icons8-linkedin-500.png", "link": "https://www.linkedin.com"}, 
            {"iconName":"icons8-x-100.png", "link": "https://www.x.com"}, 
            {"iconName":"icons8-github-500.png", "link": "https://www.github.com"}];

    return (
        <div className='container-fluid'>
            <h1 className='d-flex justify-self-start p-5'>Get In Touch With Us</h1>
            {/* First Row */}
            <div className='row pb-5'> 
                <div className='col-lg-5 col-md-5 col-sm-12 ms-5 my-3'>
                    <p className="text-justify">At EpidetectAI, we are committed to providing the best services to our clients. 
                    If you have any inquiries or would like to learn more about our large disease analytics, regional health monitoring, 
                    or anomaly detection services, please do not hesitate to contact us. You can reach us by phone or email, or simply 
                    fill out our contact form, and we will get back to you as soon as possible. 
                    We value your feedback and look forward to hearing from you.
                    </p>
                    {/* icons */}
                    <div className="d-flex row justify-content-around align-items-center flex-row">
                        {iconLinks.map((iconDetails, index) => (
                        <div key={index} className="col-2 me-3">
                            <a href={iconDetails.link} target="_blank" rel="noopener noreferrer">
                              <img src= {`icons/${iconDetails.iconName}`} className="icon-sizes" alt={`${iconDetails.iconName}`} />
                                {/* {iconDetails.detail} */}
                            </a>
                        </div>
                        ))}
                    </div>
                </div>
                <div className='col-lg-5 col-md-5 col-sm-12 offset-1'>
                    <ContactUsForm/>
                </div>
            </div>
        
        {/* Explore Our Interactive Demo */}
        <div className='row pb-3'> 
            <div className='col-lg-2 col-md-2 col-sm-3 text-center pulsing'>
                <div class="pt-5 sticky-button-container ">
                <a className='sticky-button' target='_blank' rel="noreferrer" href="https://chat.openai.com/g/g-3jdmDEXbk-epidetectgpt">
                    <div className="text-wrap">Explore Our Interactive Demo</div>
                </a>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ContactUs;