
// Contains the list of blog information to be presented on the blog page and on the 
// latest news section on the homepage. The choices for the latest news
// depends on the "featured" parameter. 
//IMPORTANT: Only 3 card details should have the "featured" parameter set to true.

const blogcards = [
    {
        "id": 0,
        "title": "Demystifying Artificial Intelligence and Machine Learning: Transforming Healthcare and Epidemiology",
        "author":"ChatGPT",
        "image": "/images/Website-Photos/37.png",
        "url": "",
        "keywords": "AI/ML, Epidemology",
        "description": "Artificial Intelligence (AI) and Machine Learning (ML) have become integral components in transforming various sectors, including healthcare and epidemiology. Understanding these concepts and their applications is crucial in harnessing their potential ...",
        "featured": false,
        "content":[
                    "Artificial Intelligence (AI) and Machine Learning (ML) have become integral components in transforming various sectors, including healthcare and epidemiology. Understanding these concepts and their applications is crucial in harnessing their potential for solving complex challenges and improving outcomes in these fields.",

                    "Understanding AI and Machine Learning",
                    "AI refers to the simulation of human intelligence in machines, enabling them to perform tasks that typically require human intelligence, such as understanding natural language, recognizing patterns, and making decisions. Machine Learning, a subset of AI, empowers computers to learn from data without explicit programming. It focuses on developing algorithms that enable computers to improve their performance on a specific task as they are exposed to more data over time.",

                    "Types of Machine Learning",
                    "   1. Supervised Learning: In supervised learning, the model is trained on a labeled dataset, where each input data point is associated with a corresponding target label. The model learns to map inputs to outputs based on the provided examples. Example: Predicting patient outcomes based on medical records (e.g., diagnosing diseases).",
                    "   2. Unsupervised Learning: Unsupervised learning involves training a model on an unlabeled dataset, where the algorithm tries to find patterns and structures in the data without explicit guidance. Example: Clustering similar patients based on their medical history for personalized treatment recommendations.",
                    "   3. Reinforcement Learning: Reinforcement learning involves training an agent to interact with an environment and learn the best actions to take to maximize cumulative rewards. Example: Optimizing treatment plans for chronic diseases by dynamically adapting to patient responses over time.",

                    "Applications in Healthcare and Epidemiology",
                    "   1. Supervised Learning in Disease Diagnosis: Supervised learning algorithms, such as deep learning models, can analyze medical images (e.g., X-rays, MRI scans) to assist radiologists in diagnosing diseases like cancer, pneumonia, or COVID-19. These models learn from large datasets of labeled images to identify patterns indicative of specific conditions, enabling faster and more accurate diagnoses.",
                    "   2. Unsupervised Learning in Drug Discovery: Unsupervised learning techniques, such as clustering and dimensionality reduction, are used in drug discovery to analyze molecular data and identify potential drug candidates. By uncovering hidden patterns and relationships in large datasets, these algorithms assist researchers in selecting promising compounds for further investigation, accelerating the drug development process.",

                    "Conclusion",
                    "AI and machine learning are revolutionizing healthcare and epidemiology by enabling more accurate diagnoses, personalized treatments, and faster drug discovery. From disease diagnosis to epidemic forecasting, these technologies offer immense potential in improving patient outcomes and public health outcomes. By understanding the various types of machine learning and their applications, we can leverage these advancements to address complex challenges and usher in a new era of healthcare innovation.",
                    "In conclusion, as AI and machine learning continue to evolve, their impact on healthcare and epidemiology will only grow, ushering in a future where data-driven insights drive decision-making and improve lives worldwide.",
                ]
    },
    {
        "id": 1,
        "title": "Top tips for data cleaning and data wrangling in large tabular datasets ",
        "author":"ChatGPT",
        "image": "/images/Website-Photos/20.png",
        "url": "",
        "keywords": " data cleaning, data wrangling, PostgresSQL,tabular datasets",
        "description": "In today's data-driven world, organizations collect vast amounts of data from various sources to derive insights, make informed decisions, and gain a competitive edge. However, the data collected is often messy, inconsistent, and incomplete, making it challenging to analyze and derive meaningful insights. ",
        "featured": true,
        // Assumption to be made is constant: No heading will have content more than 10 words, else it is a paragraph
        "content": [
                    // Heading 1
                    "Introduction",
                    // Paragraph 1
                    "In today's data-driven world, organizations collect vast amounts of data from various sources to derive insights, make informed decisions, and gain a competitive edge. \
                    However, the data collected is often messy, inconsistent, and incomplete, making it challenging to analyze and derive meaningful insights. This is where data cleaning and wrangling come into play. \
                    Data cleaning and wrangling are crucial steps in the data preparation process that involve identifying, correcting, and transforming raw data into a clean, structured format suitable for analysis. \
                    This process is particularly important when dealing with large tabular datasets, which can contain millions of records spread across numerous columns. PostgreSQL, a powerful open-source relational \
                    database management system, provides robust features and functions that facilitate data cleaning and wrangling tasks. From handling missing values to standardizing data formats and \
                    performing complex transformations, PostgreSQL offers a wide range of tools to streamline the data preparation process. ", 
                    // Paragraph 2
                    "In this guide, we'll explore top tips and strategies for cleaning and wrangling large tabular datasets using PostgreSQL. We'll cover techniques for handling missing values, \
                    standardizing data formats, normalizing data structures, cleansing text data, deduplicating records, performing data transformations, optimizing query performance, and documenting the process. \
                    By following these tips and leveraging PostgreSQL's capabilities, you'll be able to efficiently prepare your data for analysis, leading to more accurate insights and better-informed decision-making.\
                    Now, let's delve into each of these aspects in detail to understand how to effectively clean and wrangle large tabular datasets using PostgreSQL.\
                    ",
                    // Heading 2
                    "Main Content",
                    "Cleaning and wrangling large tabular datasets in PostgreSQL can be a complex task, but there are several strategies and techniques you can employ to streamline the process. Here are some top tips for data cleaning and wrangling in PostgreSQL:",
                    "   1. Understand Your Data: Before diving into cleaning and wrangling, thoroughly understand your dataset's structure, including column names, data types, and any missing or inconsistent values. This understanding will guide your cleaning and wrangling efforts.",
                    "   2. Use Constraints and Validation: Leverage PostgreSQL's constraints and validation mechanisms, such as NOT NULL constraints, UNIQUE constraints, CHECK constraints, and foreign key constraints, to ensure data integrity and prevent invalid or inconsistent data.",
                    "   3. Handle Missing Values: Identify missing values in your dataset and decide on the best approach for handling them, such as imputation, deletion, or flagging. Use PostgreSQL functions like COALESCE() or NULLIF() to handle missing values during data wrangling.",
                    "   4. Standardize Data Formats: Standardize data formats to ensure consistency and facilitate analysis. Use PostgreSQL functions like TO_DATE(), TO_TIMESTAMP(), or TO_NUMBER() to convert data between different formats as needed.",
                    "   5. Normalize Data: Normalize your data by breaking it down into smaller, more manageable tables and establishing relationships between them using foreign keys. Normalization reduces data redundancy and improves data integrity.",
                    "   6.  Cleanse Text Data: Cleanse text data by removing leading or trailing whitespace, converting text to lowercase, handling special characters, and standardizing text values. Use PostgreSQL string functions like TRIM(), LOWER(), and REPLACE() for text cleansing.",
                    "   7. Handle Duplicates: Identify and handle duplicate records in your dataset using PostgreSQL's DISTINCT keyword, GROUP BY clause, or window functions like ROW_NUMBER(). Decide whether to deduplicate based on specific columns or the entire row.",
                    "   8. Perform Data Transformation: Perform data transformation operations, such as aggregations, pivoting, and calculations, to derive new insights from your data. Utilize PostgreSQL's built-in aggregate functions (e.g., SUM(), AVG(), COUNT()), CASE statements, and window functions for data transformation.",
                    "   9. Optimize Performance: Optimize query performance by creating indexes on columns frequently used in filtering, sorting, or joining operations. Use PostgreSQL's EXPLAIN ANALYZE command to analyze query execution plans and identify potential performance bottlenecks.",
                    "   10. Document Your Process: Document your data cleaning and wrangling process, including the steps taken, transformations applied, and any assumptions made. Clear documentation ensures reproducibility and facilitates collaboration with other team members.",
                    "By following these tips and leveraging PostgreSQL's powerful features and functions, you can effectively clean and wrangle large tabular datasets to prepare them for analysis and decision-making."






                ]
    },
    {
        "id": 2,
        "title": "SORMAS: Empowering Public Health Systems in the Fight Against Infectious Diseases",
        "author":"ChatGPT",
        "image": "/images/Website-Photos/12.png",
        "url": "",
        "keywords": "SORMAS, epidemic, pandemic, infectious diseases",
        "description": "In the perpetual battle against infectious diseases, early detection, swift response, and effective management are paramount. Enter SORMAS - the Surveillance, Outbreak Response Management, and Analysis System. Developed as an open-source digital ...",
        "featured": false,
        "content": [
            "In the perpetual battle against infectious diseases, early detection, swift response, and effective management are paramount. Enter SORMAS - the Surveillance, Outbreak Response Management, and Analysis System. Developed as an open-source digital tool by the Helmholtz Centre for Infection Research (HZI) in Germany, SORMAS is revolutionizing the way public health authorities detect, track, and respond to outbreaks.",
            
            "Enhancing Surveillance Capabilities",
            "At its core, SORMAS serves as a comprehensive surveillance platform, empowering healthcare workers to collect, store, and analyze epidemiological data related to infectious diseases. Through its user-friendly interface, frontline workers can swiftly report suspected cases, track contacts, and monitor disease spread in real-time. This robust surveillance infrastructure forms the foundation for proactive intervention strategies.",
            
            "Streamlining Outbreak Response",
            "In the face of an outbreak, coordination and efficiency are critical. SORMAS offers a suite of tools to streamline outbreak response activities. From case investigation to contact tracing and laboratory testing, the system facilitates seamless communication and task assignment among response teams. By centralizing data and workflows, SORMAS empowers public health authorities to swiftly implement control measures and contain outbreaks before they escalate.",
            
            "Harnessing Data for Informed Decision-Making",
            "Data-driven decision-making lies at the heart of effective public health interventions. SORMAS provides sophisticated analysis and reporting capabilities, enabling authorities to derive actionable insights from the wealth of epidemiological data at their disposal. From generating reports and dashboards to visualizing disease trends and hotspots, the system equips decision-makers with the tools they need to respond rapidly and effectively to emerging threats.",
            
            "Fostering Integration and Interoperability",
            "In an increasingly interconnected world, interoperability is key. SORMAS is designed to seamlessly integrate with existing health information systems and surveillance networks. By adhering to data exchange standards and protocols, the system ensures interoperability with other digital health tools and platforms. This interoperability enhances collaboration and facilitates information sharing across disparate systems, bolstering the collective response to infectious diseases.",
            
            "Mobilizing Response Efforts",
            "Recognizing the dynamic nature of public health emergencies, SORMAS offers both mobile and web-based interfaces. This mobility empowers healthcare workers to report cases, access information, and coordinate response efforts from any location with internet connectivity. Whether in remote communities or urban centers, SORMAS ensures that frontline workers remain connected and equipped to respond swiftly to emerging threats.",
            
            "Real-World Impact",
            "Since its inception, SORMAS has been deployed in response to a myriad of disease outbreaks, including Ebola virus disease, Lassa fever, and most recently, COVID-19. In each instance, the system has played a pivotal role in enhancing disease surveillance, outbreak detection, and response coordination. By leveraging the power of data and technology, SORMAS has contributed to more effective public health interventions and the containment of infectious diseases.",
            
            "Conclusion",
            "In an era defined by the constant threat of emerging infectious diseases, SORMAS stands as a beacon of hope. By empowering public health systems with advanced surveillance, response, and analysis capabilities, SORMAS is transforming the way we detect, track, and respond to outbreaks. As we continue to navigate the complex landscape of global health security, SORMAS serves as a testament to the power of innovation in safeguarding public health and saving lives.",
        ]
    },
    {
        "id": 3,
        "title": "How to best leverage open-source generative AI for epidemic data",
        "author":"ChatGPT",
        "image": "/images/Website-Photos/8.png",
        "url": "",
        "keywords": "GenAI, open-source, epidemic data",
        "description":"In the face of global health crises such as pandemics and epidemics, timely and accurate data analysis is crucial for effective response and mitigation efforts. However, gathering, processing, and analyzing epidemic data can be challenging due to its sheer volume, complexity, and dynamic nature. Traditional epidemiological methods may struggle to keep pace with rapidly evolving situations, highlighting the need for innovative approaches to epidemic ...",
        "featured": true,
        "content":[
                    // Heading 1
                    "Introduction",
                    // Paragraph 1
                    "In the face of global health crises such as pandemics and epidemics, timely and accurate data analysis is crucial for effective response and mitigation efforts. However, gathering, processing, and analyzing epidemic data can be challenging due to its sheer volume, complexity, and dynamic nature. Traditional epidemiological methods may struggle to keep pace with rapidly evolving situations, highlighting the need for innovative approaches to epidemic data analysis. \
                    In recent years, the emergence of generative artificial intelligence (AI) has offered new possibilities for analyzing and understanding epidemic data. Generative AI refers to a class of machine learning techniques that enable computers to learn and generate new data samples that resemble those in the training dataset. By leveraging open-source generative AI frameworks and tools, researchers, public health officials, and data scientists can explore novel ways to analyze, simulate, and forecast epidemic dynamics. \
                    Open-source generative AI frameworks, such as TensorFlow, PyTorch, and GANs, provide accessible and customizable platforms for developing sophisticated models that can generate synthetic epidemic data, simulate disease transmission dynamics, and facilitate scenario analysis. These frameworks empower researchers to explore complex interactions between epidemiological factors, assess the effectiveness of intervention strategies, and inform evidence-based decision-making in public health policy. \
                    Furthermore, open-source generative AI enables collaborative and transparent research practices by promoting code sharing, reproducibility, and community-driven development. By leveraging shared resources and expertise, researchers can collectively tackle pressing challenges in epidemic data analysis, such as data scarcity, privacy concerns, and model interpretability. ",
                    // Paragraph 2
                    "In this guide, we will explore how to best leverage open-source generative AI for epidemic data analysis. We'll discuss various applications of generative AI in epidemic research, including data generation, simulation and forecasting, anomaly detection, scenario analysis, data augmentation, privacy-preserving data sharing, interactive data exploration, and model interpretation. Through these techniques, we aim to empower stakeholders with actionable insights, enhance epidemic preparedness, and contribute to global efforts to combat infectious diseases. \
                    Now, let's delve deeper into the potential of open-source generative AI for analyzing and understanding epidemic data, and explore practical strategies for applying these techniques in real-world scenarios.",
                    // Heading 2
                    "Main Text",
                    // Paragraph 3: Content
                    "Leveraging open-source generative AI for epidemic data can offer valuable insights and aid in various aspects of epidemic detection, forecasting, and analysis. Here are several ways to best utilize open-source generative AI for epidemic data:",
                    "   1. Data Generation: Generative AI models, such as Generative Adversarial Networks (GANs) or Variational Autoencoders (VAEs), can be trained to generate synthetic epidemic data based on existing datasets. This synthetic data can help augment limited or incomplete real-world data, enabling researchers to perform more robust analyses and experiments.",
                    "   2. Simulation and Forecasting: Generative AI models can simulate the spread of epidemics by generating synthetic populations and modeling the dynamics of disease transmission. These simulations can be used to forecast the potential trajectory of an epidemic, assess the effectiveness of intervention strategies, and evaluate the impact of various factors on disease spread.",
                    "   3. Anomaly Detection: Generative AI models can learn the normal patterns of epidemic data and detect anomalies or unusual patterns that may indicate the emergence of new outbreaks or unexpected changes in disease dynamics. By continuously monitoring epidemic data streams, these models can provide early warning signals for potential public health threats.",
                    "   4. Scenario Analysis: Generative AI models can generate alternative scenarios based on different assumptions or parameters, allowing researchers to explore various what-if scenarios and assess their potential implications for epidemic control and management. This can help policymakers and public health officials make more informed decisions in response to evolving epidemic situations.",
                    "   5. Data Augmentation: Generative AI models can be used to augment real-world epidemic datasets by generating additional synthetic samples. This can help address issues such as data imbalance or scarcity, improve the generalization capabilities of predictive models, and enhance the robustness of epidemic forecasting and analysis algorithms.",
                    "   6. Privacy-Preserving Data Sharing: Generative AI techniques, such as differential privacy and federated learning, can facilitate privacy-preserving data sharing and collaboration among multiple stakeholders while protecting sensitive information. This enables researchers and organizations to pool epidemic data from diverse sources and collectively analyze it to gain deeper insights into disease dynamics.",
                    "   7. Interactive Data Exploration: Generative AI models can generate realistic synthetic data visualizations, interactive dashboards, or immersive simulations that allow users to explore and interact with epidemic data in a dynamic and engaging manner. This enhances understanding and facilitates communication of complex epidemiological concepts to diverse audiences.",
                    "   8. Model Interpretation and Explainability: Open-source generative AI frameworks often include tools and techniques for interpreting and explaining model predictions. This can help researchers understand the underlying mechanisms driving epidemic dynamics, identify influential factors, and validate model assumptions, leading to more robust and interpretable epidemic analysis.",
                    "By leveraging open-source generative AI techniques, researchers, public health officials, and policymakers can harness the power of artificial intelligence to gain deeper insights into epidemic data, improve epidemic detection and forecasting, and ultimately enhance public health preparedness and response efforts."
                    ]
    },
    {
        "id": 4,
        "title": "Train-test data leakages: Negative impact on ML modelling and how to mitigate against it",
        "author":"ChatGPT",
        "image": "/images/Website-Photos/5.png",
        "url": "",
        "keywords": "AI, data leakage, ML preprocessing",
        "description": "In the realm of machine learning (ML), accurate model evaluation is paramount for developing reliable predictive models that generalize well to unseen data. However, data leakage poses a significant threat to the integrity of model evaluations, leading to inflated performance metrics and misleading conclusions. Data leakage occurs when information from the training dataset inadvertently leaks into the testing dataset, providing the model with access ...",
        "featured": true,
        "content":[
                    "Introduction",
                    "In the realm of machine learning (ML), accurate model evaluation is paramount for developing reliable predictive models that generalize well to unseen data. However, data leakage poses a significant threat to the integrity of model evaluations, leading to inflated performance metrics and misleading conclusions. Data leakage occurs when information from the training dataset inadvertently leaks into the testing dataset, providing the model with access to information it wouldn't have in a real-world scenario. This phenomenon can severely undermine the validity and generalization ability of ML models, impacting decision-making processes across various domains.",
                    "Negative Impacts of Data Leakage in ML",
                    "The negative impact of data leakage on ML modeling is multifaceted and can manifest in several ways:",
                    "Overestimated Model Performance: Data leakage can artificially inflate the performance metrics of ML models during evaluation. This overestimation occurs because the model inadvertently learns patterns or relationships present in the leaked data, rather than genuine predictive features.",
                    "Biased Model Selection: In the presence of data leakage, practitioners may erroneously select models or features based on inflated performance metrics observed during testing. This bias can lead to the adoption of suboptimal models that fail to generalize to new data.",
                    "Reduced Generalization Ability: ML models trained on data containing leaks may lack the ability to generalize to unseen data, as they have effectively memorized patterns specific to the training dataset. Consequently, these models may perform poorly when deployed in real-world scenarios where data distributions differ from those in the training set.",
                    "Techniques and Best Practices",
                    "To mitigate the adverse effects of data leakage on ML modeling, practitioners must employ rigorous strategies and best practices:",
                    "Strict Data Separation: Ensure a clear delineation between training and testing datasets to prevent any overlap or contamination of information. Utilize techniques such as cross-validation, time-based splitting, or stratified sampling to create independent datasets for model training and evaluation.",
                    "Feature Engineering Vigilance: Exercise caution when engineering features to avoid incorporating information that would not be available at the time of prediction. Exclude features derived from future or target-related data, as they can introduce leakage into the model training process.",
                    "Target Leakage Detection: Scrutinize the dataset and feature engineering pipeline for potential sources of target leakage, such as using information derived from the target variable or future data to create features. Remove or correct any features that could inadvertently leak information related to the target variable.",
                    "Validation Dataset Usage: Reserve a separate validation dataset, distinct from the training and testing sets, to assess model performance during development. This ensures that model evaluation remains unbiased and provides an accurate representation of generalization ability.",
                    "Randomization Techniques: Implement randomization when splitting datasets to maintain consistent data distributions across training, validation, and testing sets. Randomization helps mitigate the risk of inadvertently leaking information related to data ordering or sampling biases.",
                    "Comprehensive Model Evaluation: Evaluate models using a diverse set of performance metrics and cross-validation techniques to gain a holistic understanding of their generalization ability. Consider metrics such as precision, recall, F1 score, and area under the receiver operating characteristic curve (AUC-ROC) to assess performance across different aspects of model behavior.",
                    "By diligently implementing these mitigation strategies and fostering a culture of awareness around data leakage, practitioners can develop robust and reliable ML models that generalize well to unseen data. Additionally, ongoing education and collaboration within the ML community can further enhance understanding and adoption of best practices in data preprocessing, modeling, and evaluation."
        ]
    }
]

export default blogcards;