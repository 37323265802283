import React, {useEffect} from 'react';
// import { useIntersection } from 'react-use';
import './index.css';

import AOS from 'aos';
import 'aos/dist/aos.css';

import FeaturedCards from '../../components/featured-cards';
import Carousel from '../../components/carousel';
import TechStackInHomePage from '../../components/techStack-in-homepage';
import AboutSectionInHomePage from '../../components/about-us-in-homepage';
import ServicesInHomePage from '../../components/services-in-homepage';
// import FAQ from '../../components/faqs';
import FAQ_V2 from '../../components/faqs-v2';
import ContactUsForm from '../../components/contact-us-form';
import SideMenu from '../../components/side-menu';

import blogcards from '../../blog-vid-lists/blog-lists';
import slides from '../../blog-vid-lists/carousel-slides'

function Dummy() {
  
    useEffect(() => {
      AOS.init({});
    }, []);

    return (
    <div className="home-header">

        <SideMenu />
        
        <Carousel slides={slides}/>

        <div id="about-section-hp" className='mt-2'>
          <div data-aos="fade-left" className='home-section-containers mx-5'>
            <AboutSectionInHomePage />
          </div>
        </div>

        <div id="services-section-hp" data-aos="fade-left" className='home-section-containers mt-2'>
          <ServicesInHomePage />
        </div>

        <div id="tech-stack-hp" data-aos="fade-left" className='home-section-containers mt-3'>
          <TechStackInHomePage />
        </div>

        <div id="faq-hp" data-aos="fade-left" className='home-section-containers mt-3 w-100'>
          <h2 className='section-title text-dark'>Frequently Asked Questions</h2>
          {/* <FAQ bgColor="#f4f4f4" border="none"/> */}
          <FAQ_V2/>
        </div>

        <div id='latest-news-hp' data-aos="fade-left" className='home-section-containers mt-3'>
          <h2 className='section-title text-dark'>Latest News</h2>
          <FeaturedCards cards={blogcards}/>
        </div>

        <div id='contact-us-hp' data-aos="fade-left" className='w-75 mt-3 mb-3'>
          <h2 className='section-title-not-underlined text-dark'>Contact Us</h2>
          <p className='fs-2 fw-light text-dark'>Get in touch with EpiDetectAI.</p>
          <ContactUsForm/>
        </div>

      </div>
    );
}

export default Dummy;