import React from 'react';
import "./index.css";
// import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';

function FeaturedCards({ cards, verboseBody=false}) {
  return (
        <div className='row pt-3'>
            {cards.map((cardDetails, index) => 
                {if (cardDetails.featured === true) {
                    return (
                        <div className='col-md-4 col-sm-12 pb-2'>
                        <div key={index} className="feat-cards p-2" 
                            style={{"backgroundImage": `url(${cardDetails.image})`, }}
                            > 
                            <div className="feat-overlay"></div> 
                            <div className="card-body"> 
                                <h5 className="feat-card-title">{cardDetails.title}</h5>
                                {/* <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                                {(() => {
                                    if (verboseBody === true) {
                                        return <p className="feat-card-text">{cardDetails.description}</p>;
                                    } 
                                    }
                                )}
                                <a className="feat-card-link d-flex justify-content-end pt-4" href={`resources/blogs/${cardDetails.id}`}>Read more...</a>
                            </div>
                            </div>
                        </div>
                    );
                } }
            )} 
        </div>
  );
}

export default FeaturedCards;
