import React, { useState, useEffect } from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LoadingScreen from 'react-loading-screen';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';
import './App.css';

import NavBar from './components/navbar';
import Footer from './components/footer';
import Chatbot from './components/whatsapp-chatbot';

import Dummy from './pages/dummy-homepage';
import Services from './pages/services';
import ContactUs from './pages/contact-us';
import Resources from './pages/resources';
import BlogPage from './pages/blogs-all';

import blogcards from './blog-vid-lists/blog-lists';
import solutions from './blog-vid-lists/solutions';
import Page from './components/blogs';

import { ReactComponent as CompanyIcon } from './EpiDetectLogoOfficial-tiny-no-bg.svg';


function App() {
  const [loading, setLoading] = useState(true); // Initially set loading to true

  useEffect(() => {
    // Simulate loading delay for demonstration purposes
    const timeout = setTimeout(() => {
      setLoading(false); // Set loading to false after a delay
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);
  
  return (
    <LoadingScreen
      loading={loading}
      bgColor='#f1f1f1'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc='../../EpidetectLogoBlack.png'
      text='Loading...'>
        <div className='App'>
            <NavBar/>
            <div className='zIndex'>
              {/* <WhatsAppWidget CompanyIcon={CompanyIcon} phoneNumber="XXXXXXXXXX" /> */}
              <Chatbot/>
            </div>
            {/* <Chatbot/> */}
            {/* Wrap Route elements in a Routes component */}
            <Routes>
              <Route path="/" element={<Dummy/>} />
              <Route path="/products-and-services" element={<Services width={"20"}/>} />
              <Route path='/contact-us' element={<ContactUs/>} />
              <Route path='/resources' element={<Resources/>} />
              <Route path='/resources/blogs' element={<BlogPage/>} />
              {/* All individual blog pages, routes generated */}
              {blogcards.map(item => (
              <Route key={item.id} path={`/resources/blogs/${item.id}`} element = {<Page author={item.author} title={item.title} image={item.image} content={item.content} keywords={item.keywords}/>} />
              ))}
              {/* All solution, routes and pages generated */}
              {solutions.map((item, index) => (
              <Route key={index*10} path={`/solutions/${item.solutionFor}`} element = {<Page author={"Dr. Daniel Tom-Abba, CEO"} title={`EpiDetectAI As A Solution For ${item.solutionFor}`} image={""} content={item.article} keywords={item.keywords}/>} />
              ))}
            </Routes>
            <br/>
            <Footer/>
        </div>
    </LoadingScreen>
  );
}

export default App;
