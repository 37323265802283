import { NavLink } from "react-router-dom";
import React, { useState, useRef } from 'react';
import "./index.css"

const styles = {
  dropdown: {
    position: 'relative',
    display: 'inline-block',
  },
  dropdownToggle: {
    backgroundColor: '#0000',
    color: 'rgb(37, 31, 31)',
    padding: '10px 10px',
    fontSize: '16px',
    border: 'none',
    cursor: 'pointer',
  },
  dropdownMenu: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#f1f1f1',
    zIndex: 1,
    listStyleType: 'none',  // Removes bullet points
    padding: '0',
    margin: '0',
    marginTop: '3px',
    minWidth: '200px',
    borderRadius: '8px'
  },
  dropdownMenuVisible: {
    display: 'block',
  },
  dropdownItem: {
    color: 'rgb(37, 31, 31)',
    padding: '12px 16px',
    textDecoration: 'none',
    display: 'block',
    cursor: 'pointer'
  },

};

const SolutionDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={styles.dropdown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={dropdownRef}
    >
      <NavLink className="solution-tab-text nav-button-clr text-decoration-none" style={styles.dropdownToggle}>
        Solutions
      </NavLink>
      <ul
        style={{
          ...styles.dropdownMenu,
          ...(isOpen ? styles.dropdownMenuVisible : {}),
        }}
      >
        <li className="nav-button-clr" style={styles.dropdownItem}>
          <a href="/solutions/epidemiologists" style={styles.dropdownItem}>For Epidemiologists</a>
        </li>
        <li className="nav-button-clr" style={styles.dropdownItem}>
          <a href="/solutions/governments" style={styles.dropdownItem}>For Governments</a>
        </li>
        <li className="nav-button-clr"style={styles.dropdownItem}>
          <a href="/solutions/institutions" style={styles.dropdownItem}>For Institutions</a>
        </li>
      </ul>
    </div>
  );
};

export default SolutionDropdown;