import "./index.css";
import React from 'react'
import Videothumbnails from "./video-thumbnails";
import videos from "../../blog-vid-lists/video-list.json";
import 'bootstrap/dist/js/bootstrap.bundle'

function VideoList() {
    const count = videos.length;
    let heading = "";
    if (count > 0) {
      const noun = count > 1 ? 'Videos' : 'Video';
      heading = count + ' ' + noun;
    }

    const splitListIntoGroups = (list) => {
      const groupedItems = [];
      for (let i = 0; i < list.length; i += 3) {
        groupedItems.push(list.slice(i, i + 3));
      }
      return groupedItems;
    };
    
    // Function to render list items
    const renderListItems = () => {
      const groupedItems = splitListIntoGroups(videos);
      return groupedItems.map((group, rowIndex) => (
        <div key={rowIndex} className="row offset-2">
          {group.map((video, colIndex) => (
            <div key={colIndex} className="col">
              <Videothumbnails key={video.id} video={video} />
            </div>
          ))}
        </div>
      ));
    };
    
    return (
      <div className="container-fluid">
        {renderListItems()}
      </div>
    );
  }

  export default VideoList;