// src/Chatbot.js NOT working
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import './index.css';

const Chatbot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleChatOpen = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleSendMessage = () => {
    const phoneNumber = "XXXXXXXXXX"; // Replace with the actual phone number
    const encodedMessage = encodeURIComponent(message);
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappUrl, "_blank");
    setMessage("");
  };

  return (
    <div className="chatbot-container">
      <button onClick={handleChatOpen} className="chatbot-button">
        <FontAwesomeIcon icon={faRobot} size="2x" />
      </button>
      {isChatOpen && (
        <div className="chatbox">
          <textarea
            className="text-area"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type your message..."
          />
          <button onClick={handleSendMessage} className="send-button">
            Send
          </button>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
