import React, { useState } from 'react';
import './index.css'; // Import your CSS file

const SignInModal = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="mx-2" onClick={toggleModal}>
        Get Started
      </div>
      {showModal && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: "1000000" }}>
          <div style={{ backgroundColor: '#fff', borderRadius: '25px', padding: '25px', maxWidth: '400px', width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <h3>Sign In Options</h3>
              <button style={{ background: 'none', border: 'none', cursor: 'pointer', fontSize: '24px' }} onClick={closeModal}>
                &times;
              </button>
            </div>
            <div style={{ padding: '20px 0' }}>
            <p className='fw-normal'>Get connected to the fastest way for high impact analysis and detection of anomalous trend in epidemic data.</p>
             <br/>
              {/* <p>Choose a sign-in method:</p> */}
              <div className='d-flex justify-content-between'>
                <button className="btn btn-outline-primary" style={{ marginRight: '10px' }}>
                  Sign In with Google
                </button>
                <button className="btn btn-outline-primary">
                  Sign In with Facebook
                </button>
              </div>
              <br/>
              <div className='get-started-divider'>
                <span>OR</span>
              </div>
              <br/>
              <span className='fw-normal'>Already Registered With us?</span>
              <div className='d-flex justify-content-between' style={{ marginTop: '10px' }}>
                <button className="btn btn-outline-primary" style={{ marginRight: '10px' }}>
                  Continue with Google
                </button>
                <button className="btn btn-outline-primary">
                  Continue with Facebook
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SignInModal;

