import React from 'react';
import "./index.css";
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';

function Carousel({ slides }) {
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div className="carousel-inner">
        {slides.map((slide, index) => (
          <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`} data-bs-interval="1200">
            <div className='carousel-img-container'>
              <img src={slide.imageSrc} className="d-block w-100 carousel-img" alt={`Slide ${index}`} />
            </div>
            <div className='carousel-text-2'>
              {/* <h1 className='m-0 p-0'>EpiDetectAI</h1> */}
              <h1 className="text-light">Epidemic Detection, Prediction and Response Using AI </h1>
            </div>
            <div className="shrink px-1 mt-5 carousel-text">
              <div>{slide.description}</div>
            </div>
            <div className="carousel-content">
            <div className="button-container">
            <a className='btn btn-md' target='_blank' rel="noreferrer" href="https://chat.openai.com/g/g-3jdmDEXbk-epidetectgpt">
                <div className="hero-button">Explore Our Interactive Demo</div>
                {/* Explore Our Interactive Demo */}
              </a>
            </div>
        </div>
          </div>
        ))} 
        
      </div>
      {/* <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> */}

    {/* <div class='carousel-content'>
        <a class='btn btn-lg btn-primary' href="http://getbootstrap.com">Learn More</a>
    </div> */}

    </div>
  );
}

export default Carousel;
