// src/Faq.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import questionAnswers from '../../blog-vid-lists/faq-and-answers-list';
import './index.css';

const FAQ_v2 = () => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const handleClick = (question) => {
    setActiveQuestion(activeQuestion === question ? null : question);
  };

  return (
    <div className="faq-container pt-3">
      {Object.keys(questionAnswers).map((question, index) => (
        <div key={index} className="faq-item m-0 text-start w-100">
          <div
            className={`faq-question ${activeQuestion === question ? 'active' : ''}`}
            onClick={() => handleClick(question)}
          >
            <FontAwesomeIcon icon={activeQuestion === question ? faMinus : faPlus} /> {question}
          </div>
          {activeQuestion === question && <div className="faq-answer">{questionAnswers[question]}</div>}
        </div>
      ))}
    </div>
  );
};

export default FAQ_v2;
