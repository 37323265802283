import React, {useState} from 'react';
import './index.css'; // Import CSS file for styling
import Arrow, { DIRECTION } from 'react-arrows'

import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutSectionInHomePage = () => {

  const [expanded, setExpanded] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [valuesDictionary] = useState({
    Innovation: "At EpiDetectAI, innovation is at the core of everything we do. We strive to constantly push the boundaries of technology and develop groundbreaking solutions to tackle complex challenges.",
    Integrity: "Integrity is one of our fundamental values. We believe in honesty, transparency, and ethical conduct in all aspects of our business. We are committed to upholding the highest standards of integrity in our interactions with clients, partners, and stakeholders.",
    Collaboration: "Collaboration is key to our success. We foster a culture of teamwork and cooperation, both internally and externally. By working together with our clients, partners, and communities, we can achieve greater impact and drive positive change.",
    Safety: "All our services prioritize safety and privacy by incorporating stringent safeguards and robust data anonymization techniques. We ensure fairness and mitigate biases through rigorous testing and regular updates of our AI models. Furthermore, advanced encryption secures data both at rest and in transit, with transparent privacy policies giving users control over their data. "
  });

  // const handleBoxClick = (value) => {
  //   setSelectedValue(value);
  //   setExpanded(true);
  // };
  const handleBoxClick = (value) => {
    setSelectedValue(valuesDictionary[value]);
    setExpanded(true);
  };

  const handleCollapse = () => {
    setExpanded(false);
  };

  return (
      <div className="container-fluid">
        <h2 className="section-title">About Us</h2>
        <div className='row d-flex align-items-center justify-content-between pt-3'>
          <div className='col-md-5 col-sm-12'>
            <img src= '../../images/Website-Photos/28.png' className='img-fluid '/>
          </div>
          <div className='col-md-7 col-sm-12'>
            <p className="mt-2 section-description text-justify">
            EpiDetectAI 1.0 is an AI revolutionary project aimed at transforming public health
            surveillance, epidemic detection, and outbreak response through the integration of
            Artificial Intelligence (AI), Machine Learning (ML), and Large Language Models (LLMs).
            By developing predictive models that identify potential infectious disease
            outbreaks before they escalate, EpiDetectAI 1.0 is setting a new standard in early
            detection and efficient management of public health crises.
            </p>
          </div>
        </div>
        
        <Arrow
            className='arrow'
            from={{
                direction: DIRECTION.BOTTOM,
                node: () => document.getElementById('from'),
                translation: [-1.5, 1],
            }}
            to={{
                direction: DIRECTION.LEFT,
                node: () => document.getElementById('to'),
                translation: [-1.5, 0],
            }}
            //   onChange={...}
            />
        {/* <div className="team-section">
          <h3 className="team-title">Our Team</h3>
          <div className="team-members pb-3">
            <div className="team-member" id='from'>
              <img className="team-member-image" width="94" height="94" src="https://img.icons8.com/3d-fluency/94/person-male--v5.png" alt="person-male--v5-0"/>
              <h4 className="team-member-name">Dr. Daniel Tom-Abba</h4>
              <p className="team-member-role">CEO, EpiDetectAI</p>
            </div>
            <div className="team-member">
              <img className="team-member-image" width="94" height="94" src="https://img.icons8.com/3d-fluency/94/person-male--v5.png" alt="person-male--v5-1"/>
              <h4 className="team-member-name">Mr. David Ogebe</h4>
              <p className="team-member-role">Legal and Business Affairs</p>
            </div>
            <div className="team-member">
              <img className="team-member-image" width="94" height="94" src="https://img.icons8.com/3d-fluency/94/person-male--v5.png" alt="person-male--v5-2"/>
              <h4 className="team-member-name">Mr. Triumph</h4>
              <p className="team-member-role">AI/ML Modelling</p>
            </div>
            <div className="team-member">
            <img className="team-member-image" width="94" height="94" src="https://img.icons8.com/3d-fluency/94/person-female--v5.png" alt="person-female--v5-0"/>
              <h4 className="team-member-name">Miss. Stephanie</h4>
              <p className="team-member-role">Creative Design (UI/UX)</p>
            </div>
          </div>
        </div> */}

        <div className="row values-section pt-3">
          <h3 className="values-title">Our Values</h3>
          <div className={`${expanded ? 'col' : 'col'}`}>
          <div className={`values-list value-box-container ${expanded ? 'expanded' : ''}`}>
            <div className="value-item value-box value-box-green fw-bold text-black" onClick={() => handleBoxClick('Innovation')}>Innovation</div>
            <div className="value-item value-box value-box-orange fw-bold text-black" onClick={() => handleBoxClick('Integrity')}>Integrity</div>
            <div className="value-item value-box value-box-red fw-bold text-black" onClick={() => handleBoxClick('Collaboration')}>Collaboration</div>
            <div className="value-item value-box value-box-blue fw-bold text-black" onClick={() => handleBoxClick('Safety')}>Safety</div>
          </div>
          </div>
          {expanded && (
            <div className="col-lg-7 offset-md-0 col-md-7 offset-sm-1 col-sm-6 value-box-text bg-black">
              <p>{selectedValue}</p>
              <button className='fs-5 mt-3' onClick={handleCollapse}>Close</button>
            </div>
          )}
        </div>

        <br/>

        {/* <div className='d-flex justify-content-center mb-4'>
            <div className='ceo-comment-boxes bg-secondary mt-4 p-2 text-justify ps-2'>
                <br/> 
                <span className='d-flex text-uppercase fw-bold'>According To</span>
                <span className="spacer"></span>
                <span className='d-flex text-uppercase fw-bold'>The CEO</span>
            </div>
            <div className='ceo-comment-boxes-2 bg-black box-overlap'>
                <h2 className='d-flex justify-content-start m-0 ps-2 quote-style'>"</h2>
                <span className='fst-italic px-1 d-flex flex-column'>AI safety and privacy has been woven into the fabric of our 
                                            provisions, alongside other best practices...</span>
                <h2 className='d-flex justify-content-end pe-2 pt-2 quote-style'>"</h2>
            </div>
        </div> */}
        
      </div>
  );
};

export default AboutSectionInHomePage;