import React from "react";
import { Link } from "react-router-dom";
import './index.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import 'bootstrap/dist/css/bootstrap.min.css';

const Footer = () => {
	return (
		<div className="container-fluid footer-container-fluid border-top">
            <footer className="pt-3">
                <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-12 mb-2 align-self-center">
                    <a href="/">
                        <img src="../../images/EpiDetectLogoOfficialWebVersion.png" alt="" width="201.49px" height="180px" />
                    </a>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 offset-md-1">
                    <h4 className="text-dark text-decoration-underline">Solutions</h4>
                    <ul className="nav flex-column footer-item-sizes">
                    <li className="nav-item mb-1"><a href="/solutions/epidemiologists" className="p-0 footer-link">Epidemiologists</a></li>
                    <li className="nav-item mb-1"><a href="/solutions/governments" className="p-0 footer-link">Governments</a></li>
                    {/* <li className="nav-item mb-1"><a href="#" className="nav-link p-0 text-body-secondary">Testimonials</a></li> */}
                    <li className="nav-item mb-2"><a href="/solutions/institutions" className="p-0 footer-link">Institutions</a></li>
                    </ul>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-6 mb-2">
                    <h4 className="text-dark text-decoration-underline">Resources Hub</h4>
                    <ul className="nav flex-column footer-item-sizes">
                    {/* <li className="nav-item mb-1"><a href="/" className="p-0 footer-link">Integration Guide</a></li> */}
                    <li className="nav-item mb-1"><Link to="/resources/blogs" className="p-0 footer-link">Blog</Link></li>
                    {/* <li className="nav-item mb-1"><a href="#" className="nav-link p-0 text-body-secondary">White Papers</a></li> */}
                    <li className="nav-item mb-2"><Link className="text-decoration-none footer-link" to="/resources/#faqs">FAQs</Link></li> {/*<a href="/resources#faqs" className="p-0 footer-link">FAQs</a>*/}
                    </ul>
                </div>

                {/* <div className="col-lg-2 col-md-2 col-sm-6 mb-2">
                    <h4 className="text-dark text-decoration-underline">About Us</h4>
                    <ul className="nav flex-column footer-item-sizes">
                    <li className="nav-item mb-1"><a href="/" className="p-0 footer-link">Mission</a></li>
                    <li className="nav-item mb-1"><a href="/" className="p-0 footer-link">Vision</a></li>
                    <li className="nav-item mb-2"><a href="/" className="p-0 footer-link">Team Members</a></li>
                    </ul>
                </div> */}

                {/* <div className="col-lg-3 col-md-3 col-sm-12 mb-2">
                    <h4 className="text-dark text-decoration-underline">Getting Started</h4>
                    <ul className="nav flex-column footer-item-sizes">
                    <li className="nav-item mb-1"><a href="/" className="p-0 footer-link">Sign Up</a></li>
                    <li className="nav-item mb-2"><a href="/" className="p-0 footer-link">Login</a></li>
                    </ul>
                </div> */}

                <div className="col-lg-3 col-md-3 col-sm-12 my-3">
                    <form>
                    <h5>Subscribe to our newsletter</h5>
                    <p>Monthly digest of what's new and exciting from us.</p>
                    <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                        <label for="newsletter1" className="visually-hidden">Email address</label>
                        <input id="newsletter1" type="text" className="form-control" placeholder="Email address" fdprocessedid="hfdj2la" />
                        <button className="btn btn-primary" type="button" fdprocessedid="ozbkx">Subscribe</button>
                    </div>
                    </form>
                </div>
                </div>

                <div className="d-flex flex-column justify-content-center pt-1 pb-1 border-top mx-5">
                <p className="text-dark fs-5 mb-0">EpiDetect© 2024, Inc.</p>
                <p className="text-dark fs-6 mb-0">All rights reserved.</p>
                {/* <ul className="list-unstyled d-flex">
                    <li className="ms-3"><a className="link-body-emphasis" href="www.twitter.com"><img src="icons/icons8-twitter-500.png" width="30" height="30" /></a></li>
                    <li className="ms-3"><a className="link-body-emphasis" href="www.instagram.com"><img src="icons/icons8-instagram-500.png" width="30" height="30" /></a></li>
                    <li className="ms-3"><a className="link-body-emphasis" href="www.facebook.com"><img src="icons/icons8-facebook-500.png" width="30" height="30" /></a></li>
                </ul> */}
                </div>
            </footer>
            </div>
        );
    };

export default Footer;
