// BlogPage.js
import React from 'react';
import blogcards from '../../blog-vid-lists/blog-lists';
import BlogSummary from '../../components/blog-column';
import FAQ from '../../components/faqs';
import VideoList from '../../components/videos';
import "./index.css"

// import { Link, NavLink } from "react-router-dom";

const Resources = () => {

  return (
    <div className="container-fluid blog-list-container mb-3">
      <h1 className='d-flex justify-self-start p-5'>Resources Hub</h1>
      <span className='d-flex justify-self-start ps-5'>Stay up to date with our blogs, videos, and FAQs, designed to help users understand and utilize EpiDetectAI.</span>

    {/* boundary tags for the sticky button */}
    {/* <>  */}
      <div className='sticky-element mb-5'>
              {/* <br/> <br/> */}
                <div className='col-lg-2 col-md-2 col-sm-3 text-center pulsing'>
                    <div className="pt-5 sticky-button-container">
                    <a className='sticky-button' 
                        target='_blank' rel="noreferrer" 
                        href="https://chat.openai.com/g/g-3jdmDEXbk-epidetectgpt">
                            <div className="text-wrap">Explore Our Interactive Demo</div>
                    </a>
                </div>
            </div>
      </div>
      <div className="row ps-5">
            <div className='fs-2 text-decoration-underline'>Latest Trends and Insights</div>
            <p className='fw-normal py-2'> <a className='text-decoration-underline' href='/resources/blogs'>Click here</a> to access all our publications. </p>
            {blogcards.map((post, index) => (
                <BlogSummary
                    key={index}
                    index={index}
                    title={post.title}
                    description={post.description}
                    keywords={post.keywords}
                    id={post.id}
                />
                ))}
      </div>

      {/* video list */}
      {/* <div className="row pt-5">
      <div className='fs-2 text-decoration-underline pb-2 ps-5'>Videos</div>
        <VideoList/>
      </div> */}
    {/* </> */}
      
      <div id='faqs' className="row pt-5">
      <div className='fs-2 text-decoration-underline'>Frequently Asked Questions</div>
        <FAQ/>
      </div>

    </div>
  );
};

export default Resources;
