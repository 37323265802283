import React, { useState } from 'react';
import { Link, NavLink } from "react-router-dom";
import SolutionDropdown from "../solution-tab-menu";
import SignInModal from "../signIn-Modal";
import "./index.css";
// import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap.min.css';


function NavBar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  const handleNavLinkClick = () => setIsNavCollapsed(true);

  return (
    <nav className="navbar navbar-expand-lg nav-white sticky-top"> {/*fixed-top*/}
      <div className="container-fluid border-bottom"> 

        <Link className="navbar-brand d-flex align-items-center" to="/">
          <img src="../../EpiDetectLogoOfficial-tiny-no-bg.png" alt="EpiDetect-Logo" width="56" height="53" className="d-inline-block align-text-top" />
          <img src="../../Epidetect AI Text_png.png" alt="EpiDetect-Logo" width="80%" height="40px" className="d-inline-block align-text-top" />
        </Link>
        {/* <span className="pt-1 sub-heading text-dark">Epidemic Detection, Prediction and Response Using AI </span> */}

        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>  */}
        <button className="custom-toggler navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
        <span className="navbar-toggler-icon"></span>
      </button>
          
        <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`} id="navbarNav"> 
          <ul className="navbar-nav fw-bold d-flex align-items-center justify-content-center">
                <li className='get-started nav-button-clr mt-1 py-2 nav-item'>
                    <SignInModal />
                </li>
                {/* <li className="nav-item nav-button-clr mt-1"> 
                  <NavLink
                      onClick={handleNavLinkClick}
                      to="products-and-services"
                      className={({ isActive }) =>
                        isActive ? 'nav-link active' : 'nav-link text-dark'}>
                      Our Services
                    </NavLink>
                </li> */}
              {/* <li className="nav-item nav-button-clr mt-1">
                <NavLink
                    onClick={handleNavLinkClick}
                    to="resources"
                    className={({ isActive }) =>
                      isActive ? 'nav-link active' : 'nav-link text-dark'}>
                    Resources
                  </NavLink>
                </li> */}

              <li className="nav-item nav-button-clr py-2 mt-1">
                  <SolutionDropdown />
              </li>

              <li className="nav-item nav-button-clr mt-1">
                <NavLink
                    onClick={handleNavLinkClick}
                    to="contact-us"
                    className={({ isActive }) =>
                      isActive ? 'nav-link active' : 'nav-link text-dark'}>
                    Contact Us
                  </NavLink>
                </li>

                <li className="nav-item mt-1"> 
                <div className="fw-bold mb-2 ms-3 flash-button epidetect-nav-btn">
                    <a className='btn btn-md' target='_blank' rel="noreferrer" href="https://chat.openai.com/g/g-3jdmDEXbk-epidetectgpt">
                    Try EpiDetectGPT! </a>
                  </div>
                </li>
                </ul>
              </div>
              </div>

            </nav>
  );
}

export default NavBar;
