const services = [
    {
        title: 'EpiDetectAI Integratable Modules',
        image: '../../images/Website-Photos/33.png',
        paragraph: 'Via integration with SORMAS Core 2.0, EpiDetectAI provides automated alerts and recommendations, facilitating the swift mobilization of public health resources.',
        benefits: ["Modular design ensures easy integration with existing epidemic data-collection systems", 
                    "Facilitating human-machine collaboration and thus automating laborious exploration of vast observations", 
                    "Facilitating rapid response, thereby nipping epidemic risk factors in the bud"],
        id_names: "integratable-modules" 
    },
    {
        title: 'EpiDetectGPT',
        image: '../../images/Website-Photos/9.png',
        paragraph: 'This mode of our  EpiDetectAI  utilises state-of-the-art large language models (LLM) to extract useful insights from vast epidemiology data and  provision a platform for an interactive communication  between domain experts and their observations.',
        benefits: ["Interpreting complex epidemiological data to produce actionable insights.", 
                    "Provision of interactive user-experience and the elimination of exhaustive manual analysis.", 
                    "Filling in missing or incomplete epidemic data by generating plausible values based on the observed data distribution"],
        id_names: "gen-modelling" 
    },
    {
        title: 'Predictive Modelling',
        image: '../../images/Website-Photos/18.png',     
        paragraph: 'EpiDetectAI  utilises state-of-the-art large statistical models to learn trends in disease populations and spread, thus producing an interactive communication link  between epidemic experts and their data.',
        benefits: ["Identifying anomalies or outliers in epidemic data by learning the underlying patterns and distributions.", 
                    "Forecasting future disease burden and healthcare needs based on historical data and current trends", 
                    "Assessing the risk of disease transmission in different population groups or geographical regions based on demographic, environmental, and socio-economic factors"],
        id_names: "pred-modelling"  
      },
    {
        title: 'Regional Health Monitoring',
        image: '../../images/Website-Photos/2.png',     
        paragraph: 'At EpidetectAI, we understand the importance of early detection of potential health issues. Our anomaly detection service uses advanced data analytics to identify unusual patterns and trends in health data, enabling you to detect potential health issues before they become emergencies. ',
        benefits: ["Advanced, decentralised data analytics of large influx of daily data at various national ports. ", 
                    "Flexible, adaptable, and scalable anomaly detection service.", 
                    "Facilitating critical decisions  for effective health care and public health. "],
        id_names: "health-monitoring"
    }
  ];
  
  export default services;