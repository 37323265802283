import React from 'react';
import "./index.css";
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';

import services from '../../blog-vid-lists/services-expanded';

function Services({width="70"}) {
  return ( 
    <div className='container-fluid'>
        <h1 className='d-flex justify-self-start p-5'>Services We Offer</h1>
        <span className='d-flex justify-self-start ps-5'>Here are some functionalities provided by EpiDetectAI. Click banners to view full benefits below.</span>
        <div className='sticky-element'>
                <div className='col-lg-2 col-md-2 col-sm-3 text-center pulsing'>
                    <div className="pt-5 sticky-button-container">
                    <a className='sticky-button' 
                        target='_blank' rel="noreferrer" 
                        href="https://chat.openai.com/g/g-3jdmDEXbk-epidetectgpt">
                            <div className="text-wrap">Explore Our Interactive Demo</div>
                    </a>
                    </div>
                </div>
        </div>
        <br/> <br/>
        
        {/* First Row */}
        <div className='row pb-3'> 
            <div className='col-md-4 col-sm-6 offset-md-2 my-3'>
                <a href='#integratable-modules' className='text-decoration-none'> 
                <div className="card top-service-card d-flex align-items-stretch h-100">
                    <h3 className="card-title text-dark pt-2">Integratable Modules</h3>
                    <div className="card-body"> 
                        <p>We provide in-depth analysis of diseases on a large scale, 
                            enabling you to make informed decisions based on data-driven insights.</p>
                    </div>                                                                            
                </div>
                </a>
            </div>
            <div className='col-md-4 col-sm-6 offset-md-1 my-3'>
                <a href='#gen-modelling' className='text-decoration-none'>  
                <div className="card top-service-card d-flex align-items-stretch h-100">
                    <h3 className="card-title text-dark pt-2">Generative Modelling</h3>
                    <div className="card-body"> 
                        <p>We provide an interactive platform for extraction of 
                            useful insights from vast epidemiology data.</p>
                    </div>
                </div>
                </a>
            </div>
        </div>

        {/* Second Row: Predictive Modelling and Regional Health Monitoring */}
        <div className='row pb-5'> 
            <div className='col-md-4 col-sm-6 offset-md-3 my-3'>
                <a href='#pred-modelling' className='text-decoration-none'> 
                <div className="card top-service-card d-flex align-items-stretch h-100">
                    <h3 className="card-title text-dark pt-2">Predictive Modelling</h3>
                    <div className="card-body"> 
                        <p>We provide in-depth analysis of diseases on a large scale, 
                            enabling you to make informed decisions based on data-driven insights.</p>
                    </div>                                                                            
                </div>
                </a>
            </div>
            <div className='col-md-4 col-sm-6 offset-md-1 my-3'>
                <a href='#health-monitoring' className='text-decoration-none'>
                <div className="card top-service-card d-flex align-items-stretch h-100">
                    <h3 className="card-title text-dark pt-2">Regional Health Monitoring</h3>
                    <div className="card-body"> 
                        <p>We provide real-time monitoring of regional health, 
                            allowing you to detect and respond to potential outbreaks 
                            and health emergencies quickly.</p>
                    </div>
                </div>
                </a>
            </div>
                        
            {/* Explore Our Interactive Demo */}
            {/* <div className='row pb-3'>  */}
            {/* <div className='sticky-element mb-5'>
                <div className='col-lg-2 col-md-2 col-sm-3 text-center pulsing'>
                    <div className="pt-5 sticky-button-container">
                    <a className='sticky-button' 
                        target='_blank' rel="noreferrer" 
                        href="https://chat.openai.com/g/g-3jdmDEXbk-epidetectgpt">
                            <div className="text-wrap">Explore Our Interactive Demo</div>
                    </a>
                    </div>
                </div>
            </div> */}
        </div>

        <div className='fs-2 text-decoration-underline pb-3'>Full Features and Benefits</div>
        {/* Add loop on full details of each service*/}
            {services.map((cardDetails, index) => 
                <div key={index+5} className='row m-0 pb-3'> 
                    {/* <div className='col-2 offset-1 d-flex justify-content-end'>
                        <div className='text-overlay'>{cardDetails.title}</div> 
                        <img className='side-img' src={`${cardDetails.image}`} alt={`service-card-details-${index}`}/>
                        <div className='text-overlay'>{cardDetails.title}</div>
                    </div> */}
                    {/* <div className='ser-img-container col-md-2 col-sm-2 offset-md-2 offset-sm-3 d-flex flex-column justify-content-center align-items-center'>
                        <img className='img-fluid h-100 card-img-sideways' src={`${cardDetails.image}`} alt={`service-card-details-${index}`}/>
                    </div> */}
                    <div className={index % 2 === 0 ? 'col-md-10 offset-md-2 col features-container':'col-md-10 offset-md-2 col features-container-right'}>
                        <div className="card custom-card border-2" id={`${cardDetails.id_names}`}>
                            {/* 
                            <div className='ser-img-container'>
                                <img className="custom-card-img" src={`${cardDetails.image}`} alt={`service-card-details-${index}`}/>
                                <div className="text-overlay">Centered Text</div>
                            </div> */}
                            <img className={index % 2 === 0 ? "custom-card-img":"custom-card-img-right"} src={`${cardDetails.image}`} alt={`service-card-details-${index}`}/>
                            <span className={index % 2 === 0 ? 'service-card-title fw-bold service-card-flexy pt-3':'service-card-title fw-bold service-card-flexy-right pt-3 ps-3'}> {`${cardDetails.title}`} </span>
                            <p className={index % 2 === 0 ? 'service-card-flexy text-start pe-2':'service-card-flexy-right text-start ps-3'}>{`${cardDetails.paragraph}`}</p>
                            <div className={index % 2 === 0 ? "card-body custom-card-body":"card-body custom-card-body-right"}> 
                                <h5 className="d-flex text-dark fw-bold text-decoration-underline">Benefits</h5>
                                <ul className='text-dark lh-base'>
                                    {/* Map over the items array and create list items */}
                                    {cardDetails.benefits.map((benefit, index2) => (
                                    <li key={index2} className='text text-start'>{benefit}</li>
                                    ))}
                                </ul>
                                {/* <a href={cardDetails.url} className="card-link feat-card-link d-flex justify-content-end pt-4">Read more...</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            )} 

        {/* insert footer */}
        
        </div>
  );
}

export default Services;
