// BlogPost.js
import React from 'react';
import "./index.css"

const BlogSummary = ({index, id, image, title, keywords, description, useImageAsBg=false}) => {
  return (
    // <>
    // {/* this uses the image as the background, and is for the blog-only link */}
    //   {useImageAsBg===true ? (
    //     <a href={`/resources/blogs/${id}`} className= {index % 2 === 0 ? "col-8 offset-3 mt-4 card": "col-8 offset-2 mt-4 blog-container card"} 
    //       style={{"backgroundImage": `url(${image})`}}
    //     >
    //       <div className='card-body card-text-set'>
    //         <h2 className="text-center card-title mb-3">{title}</h2>
    //         <p className="card-text text-justify">{description}</p>
    //         <p className="card-text text-start"> <strong>Keywords:</strong> {keywords}</p>
    //       </div>
    //   </a>
      
    //   ) :
      
    //   // This uses the default background, and is used in the resourses page
    //   (  
    //     <a href={`/resources/blogs/${id}`} className= {index % 2 === 0 ? "col-4 offset-2 mt-4 card": "col-4 offset-1 mt-4 blog-container card"}>
    //       <div className='card-body'>
    //         <h2 className="text-center card-title mb-3">{title}</h2>
    //         <p className="card-text text-justify">{description}</p>
    //         <p className="card-text text-start"> <strong>Keywords:</strong> {keywords}</p>
    //       </div>
    //     </a>
    //   )}
    // </>
    <>
      {useImageAsBg===true ? (
        // This uses the image as the card background, and is ONLY for the blog page
          <a href={`/resources/blogs/${id}`} className= {index % 2 === 0 ? "col-md-8 offset-md-3 col-sm-12 col-sm-0 mt-4 card rounded-4": "rounded-4 col-md-8 offset-md-2 col-sm-12 col-sm-0 mt-4 blog-container card"} 
          style={{"backgroundImage": `url(${image})`}}
            >
              <div className="feat-overlay rounded-4 m-0 p-0"></div> 
              <div className='card-body card-text-set'>
                <h2 className="text-center card-title mb-3">{title}</h2>
                <p className="card-text text-justify">{description}</p>
                <p className="card-text text-start"> <strong>Keywords:</strong> {keywords}</p>
              </div>
          </a>
      ) : (
        // This uses the default background, and is used in the wider resourses page ONLy
        // Using the id numbers, enforce that only the first 4 blog items will be displayed on the resourses page
        <>
          {id < 4 ? (
            <a href={`/resources/blogs/${id}`} className= {index % 2 === 0 ? "col-md-4 offset-md-2 col-sm-9 offset-sm-3 mt-4 card": "col-md-4 offset-md-1 col-sm-9 offset-sm-3 mt-4 blog-container card"}>
              <div className='card-body'>
                <h2 className="text-center card-title mb-3">{title}</h2>
                <p className="card-text text-justify">{description}</p>
                <p className="card-text text-start"> <strong>Keywords:</strong> {keywords}</p>
              </div>
            </a>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export default BlogSummary;
