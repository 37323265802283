import React, { useState } from 'react'
import "./index.css"

// const TechnologyList = ({ technologies }) => {
//   return (
//     <div className="row d-flex align-items-center justify-content-center">
//       {technologies.map((tech, index) => (
//         <div key={index} className="scol-md-3 col-sm-3 py-3">
//           <div className="card mb-3" style={{ width: '94px', height: '94px' }}>
//             <img src={`https://via.placeholder.com/150x150?text=${tech.name}`} className="card-img-top" alt={tech.name} />
//             <div className="card-body">
//               <p className="card-text">{tech.name}</p>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

const TechnologyList = ({ technologies }) => {
    // Import images from the directory
    const importAll = (r) => {
      let images = {};
      r.keys().map((item, index) => { images[item.replace('../', '')] = r(item); });
      return images;
    }
    
    const images = importAll(require.context('../../../public/icons/tech-stack', false, /\.(png)$/));
    // console.log(`images: ${images}`)
  
    return (
      <div className="row d-flex align-items-center justify-content-around">
        {technologies.map((tech, index) => {
          // Check if tech.name matches or is inside any of the image filenames
          const matchingImage = Object.keys(images).find(image => image.includes(tech.name.toLowerCase().split(".")[0]));
          return (
            <div key={index} className="col-md-3 col-sm-3 pt-3" >
              <div className="card mb-3" style={{ width: '150px', height: "150px"}}>
                {/* Display the associated image if found, otherwise display a placeholder */}
                <img 
                  src={matchingImage ? images[matchingImage] : `https://via.placeholder.com/150x150?text=${tech.name}`} 
                  className="card-img-top img-fluid" 
                  alt={tech.name} 
                />
                {/* <div className="card-body pt-3">
                  <p className="card-text">{tech.name}</p>
                </div> */}
              </div>
              <span className="d-flex justify-content-start ps-5 fw-normal text-black fs-6 mt-3">{tech.name}</span>
            </div>
          );
        })}
      </div>
    );
  };

const TechStackInHomePage = () => {
  const [selectedCategory, setSelectedCategory] = useState('UI/UX');
  const [showTooltip, setShowTooltip] = useState(false);

  const categories = [
    { name: 'UI/UX', technologies: ['Sketch', 'Figma', 'Adobe XD'] },
    { name: 'WEB DEVELOPMENT', technologies: ['React', 'Angular', 'Vue.js'] },
    { name: 'AI / MACHINE LEARNING', technologies: ['TensorFlow', 'PyTorch', 'Scikit-learn'] },
    { name: 'SECURITY', technologies: ['SSL', 'Firewalls', 'Encryption'] },
    { name: 'WEB APPLICATION', technologies: ['Node.js', 'Express', 'Django'] },
  ];

  const handleTechStackButtonHover = () => {
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000); // Hide the tooltip after 1 second
  };

  return (
    <div className="container-fluid">
      <h2 className="section-title">Tech Stack</h2>
          <p className="mt-2 section-description">
            As innovators and groundbreakers, we endeavor to keep abreast with the latest and best 
            technologies needed to maximise performance, ease-of-use and accessibility of our products.
            </p>
      <div className="row">
        <div className="col-md-3 col-sm-12">
          <div className="category-buttons">
            {categories.map((category, index) => (
              <button
                key={index}
                className={`btn btn-block ${selectedCategory === category.name ? 'btn-success' : 'btn-inherit'}`}
                onMouseEnter={() => handleTechStackButtonHover()}
                onMouseLeave={() => setShowTooltip(false)}
                onClick={() => setSelectedCategory(category.name)}
                >
                {category.name}
                {showTooltip && <span className="techStack-tooltip">Click to view tools in use</span>}
              </button>
            ))}
          </div>
        </div>
        <div className="col-md-9 col-sm-12">
          <div className="technology-list">
            {selectedCategory && (
              <TechnologyList
                technologies={categories.find(category => category.name === selectedCategory).technologies.map((tech, index) => ({ name: tech }))}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export default TechStackInHomePage;
