// FormComponent.js
import React from 'react';
import fieldNames from "../../blog-vid-lists/contactUs-fieldNames"

const ContactUsForm = () => {
  return (
    <div className="container rounded-3 border border-dark p-4" style={{ backgroundColor: '#EDF4F2' }}>
      <form className='text-dark'>
        {fieldNames.map((fieldName, index) => (
          <div className="mb-3 d-flex flex-column ps-3" key={index}>
            <label className="form-label text-start fs-5">{fieldName}</label>
            {fieldName === "Message" ? (
              <textarea
                className="form-control form-control-lg muted-text fs-6"
                placeholder="Start typing your message"
              />
            ) : (
              <input
                type="text"
                className="form-control"
                placeholder={`Enter your ${fieldName.toLowerCase()}`}
              />
            )}
          </div>
        ))}
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>
    </div>
  );
};

export default ContactUsForm;
