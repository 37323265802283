import React from 'react';
import './index.css';  // Import the CSS file for styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faMinus } from '@fortawesome/free-solid-svg-icons';

const SideMenu = () => {
  return (
    <div className="side-menu">
      {/* <div className="side-menu-header">
        <h1>Menu</h1>
      </div> */}
      <ul className="side-menu-list">
        {/* <li><a href="#carousel">
            Top
            <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon" />
        </a></li> */}
        <li><a href="#about-section-hp">
            About Us
            <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
        </a></li>
        <li><a href="#services-section-hp">
            Services
            <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
        </a></li>
        <li><a href="#tech-stack-hp">
            Technologies
            <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
        </a></li>
        <li><a href="#faq-hp">
            FAQ
            <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
        </a></li>
        <li><a href="#latest-news-hp">
            Latest News
            <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
        </a></li>
        <li><a href="#contact-us-hp">
            Contact Us
            <FontAwesomeIcon icon={faCircle} className="circle-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
            <FontAwesomeIcon icon={faMinus} className="line-icon line-icon-hidden" />
        </a></li>
      </ul>
    </div>
  );
};

export default SideMenu;