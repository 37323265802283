// BlogPage.js
import React from 'react';
import blogcards from '../../blog-vid-lists/blog-lists';
import BlogSummary from '../../components/blog-column';
// import "./index.css"

// import { Link, NavLink } from "react-router-dom";

const BlogPage = () => {

  return (
    <div className="container-fluid blog-list-container mb-3">
      <h2 className='d-flex justify-self-start p-5'>All Blogs</h2>
      <span className='d-flex justify-self-start ps-5 mb-3'>To read full articles, click on the respective blog card.</span>

    {/* boundary tags for the sticky button */}
    {/* <>  */}
      <div className='sticky-element mb-5'>
              {/* <br/> <br/> */}
                <div className='col-lg-2 col-md-2 col-sm-3 text-center pulsing'>
                    <div className="pt-5 sticky-button-container">
                    <a className='sticky-button' 
                        target='_blank' rel="noreferrer" 
                        href="https://chat.openai.com/g/g-3jdmDEXbk-epidetectgpt">
                            <div className="text-wrap">Explore Our Interactive Demo</div>
                    </a>
                </div>
            </div>
      </div>
      <div className="row ps-5">
            {blogcards.map((post, index) => (
                  <BlogSummary
                      key={index}
                      index={index}
                      image={post.image}
                      title={post.title}
                      description={post.description}
                      keywords={post.keywords}
                      id={post.id}
                      useImageAsBg={true}
                  />
                ))}
      </div>

    </div>
  );
};

export default BlogPage;
