import React from 'react';
import "./index.css";
import 'bootstrap/dist/js/bootstrap.bundle'
// import 'bootstrap/dist/css/bootstrap.min.css';

import services from '../../blog-vid-lists/services-expanded';

function ServicesInHomePage() {
  return ( 
    <div className='container-fluid'>
        <h2 className='section-title'>Our Services</h2>

        <p className="mt-2 section-description">
            At EpiDetectAI, we offer a range of services designed to expedite epidemic detection while maximising ease-of-use. 
            Our expert team are committed to providing customized solutions that meet your unique requirements, 
            ensuring you achieve your goals with efficiency and precision. Listed below are some of our service offerings: 
        </p>

            {services.map((cardDetails, index) => 
                <div key={index+5} className='row m-0 pb-3'> 
                    <div className={index % 2 === 0 ? 'col-md-12 col features-container':'col-md-12 col features-container-right'}>
                        <div className="card custom-card border-2" id={`${cardDetails.id_names}`}>
                            <img className={index % 2 === 0 ? "custom-card-img":"custom-card-img-right"} src={`${cardDetails.image}`} alt={`service-card-details-${index}`}/>
                            <span className={index % 2 === 0 ? 'service-card-title fw-bold service-card-flexy pt-3 fs-5':'service-card-title fw-bold service-card-flexy-right pt-3 ps-3 fs-5'}> {`${cardDetails.title}`} </span>
                            <p className={index % 2 === 0 ? 'service-card-flexy text-start pe-2 fs-6':'service-card-flexy-right text-start ps-3 fs-6'}>{`${cardDetails.paragraph}`}</p>
                            <div className={index % 2 === 0 ? "card-body custom-card-body":"card-body custom-card-body-right"}> 
                                <h5 className="d-flex text-dark fw-bold text-decoration-underline">Benefits</h5>
                                <ul className='text-dark fs-6'>
                                    {/* Map over the items array and create list items */}
                                    {cardDetails.benefits.map((benefit, index2) => (
                                    <li key={index2} className='text text-start'>{benefit}</li>
                                    ))}
                                </ul>
                                {/* <a href={cardDetails.url} className="card-link feat-card-link d-flex justify-content-end pt-4">Read more...</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            )} 
        
        </div>
  );
}

export default ServicesInHomePage;
